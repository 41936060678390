import $, { makeArray } from "jquery";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import logo from "../../assets/logo.jpg";

import "./navbar.css";

function MakeResponsive() {
  var x = document.getElementById("myTopnav");
  if (x.className === "topnav") {
    x.className += " responsive";
  } else {
    x.className = "topnav";
  }
}

function Navbar() {
  const history = useHistory();
  return (
    <div className="topnav" id="myTopnav">
      <a
        href="javascript:void(0);"
        className="icon toggle"
        onClick={() => MakeResponsive()}
      >
        <i className="fa fa-bars"></i>
      </a>
      <div className="navbar-content" id="mainNav">
        <a className="a-logo" onClick={() => history.push("/")}>
          <img src={logo} className="logo" />
        </a>
        <a className="item" href="#" onClick={() => history.push("projects")}>
          Projects
        </a>
        <a
          className="item"
          href="#"
          onClick={() => history.push("media-theory")}
        >
          Media Theory
        </a>
        <a
          className="item"
          href="#"
          onClick={() => history.push("storytelling")}
        >
          Story Creation
        </a>
        <a
          className="item"
          href="#"
          onClick={() => history.push("human-centered-design")}
        >
          Human Centered Design
        </a>
        <a
          className="item"
          href="#"
          onClick={() => history.push("critical-design")}
        >
          Critical Design
        </a>
      </div>
    </div>
  );
}

export { Navbar };
