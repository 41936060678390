import React from 'react'
import CardComponent from '../../components/card/CardComponent'
import PulseCardComponent from '../../components/card/PulseCardComponent'

import assignments from '../../assets/assignments.png'
import essay from '../../assets/essay1.png'

function MediaTheory() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-4">
                    <CardComponent link="details-assignments" image={assignments} title="Reflection on Media Assignments" content="
                    In the following section, you can read about my reflections on the different movies I had to watch, challenges I had to experience and the application I had to use. I gave my pure opinion on what I experienced during these assignments.
                    "/>
                </div>
                <div className="col-4">
                    <CardComponent link="details-essay" image={essay} title="The Essay" content="
                    As a future designer, I would like to learn a bit more about the evolution of design and what exactly is the right thing to focus on when designing. I had a vision formulated in my mind, that we first design for the user, than for the service, that is why I wanted to argument it in this essay. In my opinion there still lots of companies struggling to find that right strategy towards successful and loved product from the potential users."/>
                </div>
                <div className="col-4">
                    <PulseCardComponent title="Personal Reflection" content="
                    This media theory class opened my eyes and I started thinking a lot more about how media affects me on daily basis. Thanks to the small assignments we had and the articles we had to read, I realized how much effect media has on our thoughts and actions. How it changes the way we perceive ourselves and the others around us, moreover, it changes our behavior. I would say that I am typically skeptical about any kind of media sources and I would double check it, but after this course I am even more scared and afraid of what media can do to humans, their behavior.
                    “We shape our tools and thereafter our tools shape us” - Marshall McLuhan
                    Perhaps the most eye-caching article for me was about interaction design and the technological mediation as a way of better understanding the relation/interaction between technologies, humans, and the world. Researching that topic was also helpful for the interaction design part of my design challenge. It made me look at the technology design from a more philosophical point of view and not just from the point of software developer."/>
                </div>
            </div>
        </div>
    );
}

export default MediaTheory;