import React from "react";

import PDF from "../../documents/vega.pdf";
import detailsImage from "../../assets/vega-team.png";

import "./DetailsPage.css";

function VegaDetailsPage() {
  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row justify-content-md-center">
        <h1>Vega Collaboration</h1>
      </div>
      <div className="row">
        <div className="col-6">
          <p>
            This minor kicked-off with an intercultural project with a school
            from South Africa. This was the first time Fontys makes such a
            long-distance collaboration with another school. The project was
            about creating our own story world which will convey the message of
            the movie Before the flood using transmedia storytelling.
          </p>
          <p>&nbsp;</p>
          <p>
            I have used the STARR methodology to describe what i and we as a
            group experienced through this collaboration.
          </p>
        </div>
        <div className="col-6" align="center">
          <img className="right-image" src={detailsImage} />
        </div>
      </div>
      <div className="row">
        <div className="button-group">
          <a href={PDF} target="_blank">
            <button className="btn btn-outline-danger">Document</button>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <h3>Reflection</h3>
          <p>
            This collaboration was something unexpected but rather interesting.
            I found this 2 weeks-project quite difficult, because of the hard
            deadlines and the nature of the assignments. Nevetheless, the end
            product we delivered was quite satisfying, which was verified by the
            badge for the best overall product.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default VegaDetailsPage;
