import React from 'react'

import PDF from '../../documents/prototypes.pdf'
import prototypes from '../../assets/details-prototype.png'

import './DetailsPage.css'

function PrototypesPage() {
    return (
        <div className="container-fluid d-flex flex-column">
            <div className="row justify-content-md-center">
                <h1>Prototypes</h1>
            </div>
            <div className="row">
                <div className="col-6">
                    <p>
                    Each one of us had to make a prototype for at least two components of the application. 
                    I choose to work on the notifications, the machine job history and the machine details screens. 
                    For the notifications i started with sketches but for the other two topics i just experimented in Adobe XD. 
                    I was mainly inspired by Mike and Bart's designs.
                    </p>
                    <p>
                    I had to design a way for both of our target users to receive notification concerning the status of the machines. From what we understood from the company and one of their clients, that the current system does not provide a way of notifying when not logged in the human machine interface. Sometimes even if you are logged in, it is hard to see when something is wrong.
                    My idea was to have two levels of notification, a high-level one for smart phones and watches and detailed one for the web app. The high-level notification will trigger the user (operator/manager) when an error/warning appears. By clicking on the link in the notification they will get transferred to the web app where they can see detailed explanation of the notification. I made a low fidelity paper sketches, first part of the document.
                    </p>
                    <p>
                    For the machine job history and machine details I wanted to go for a digital prototype, but I did not know what software to use.
                    That is why I reached out to my teammates and they suggested me Adobe XD. This seemed like a nice way to develop my skills even further.
                    I had to watch a few videos to learn how to work with it, but when I got it going it went smoothly. As for the design decisions i used Mikes and Bart early prototypes to get inspirations. I maintained the same color palette and card forms. I also introduced a new design order, based on what i got as an insights from the interview i participated in.
                    </p>
                    <p>
                    Although i did not work on my designs further, because we chose one of the other member's designs, i am proud of what i achieved and i definitely learned a lot not only from my attempt but also from my teammates ideas.
                    </p>
                    <a href={PDF}  target="_blank"><button className="btn btn-outline-danger">Prototypes</button></a>
                </div>
                <div className="col-6" align="center">
                    <img className="right-image" src={prototypes}/>
                </div>
            </div>
        </div>
    );
}

export default PrototypesPage;