import React from "react";

import PDF from "../../documents/K3D Interview - Answers.docx.pdf";
import questionsResults from "../../assets/interview-result.png";

import "./DetailsPage.css";

function InterviewQuestionsResultsPage() {
  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row justify-content-md-center">
        <h1>K3D Interview Results/Report</h1>
      </div>
      <div className="row">
        <div className="col-6">
          <p>
            K3D is one of the customers of Additive Industries which a group of
            two students, including me, interviewed. The interview was online
            using Microsoft Teams. We shared our first sketches and ideas through teams and got some insights. This results
            document was created to show the outcome of the interview and make a
            brief conclusion.
          </p>
        </div>
        <div className="col-6" align="center">
          <img className="right-image" src={questionsResults} />
          <figcaption>
            This is a screenshot showing the current status of their machine
            details screen. Provided by the K3D person interviewed
          </figcaption>
        </div>
        <p>&nbsp;</p>
      </div>
      <div className="row">
        <div className="button-group">
          <a href={PDF} target="_blank">
            <button className="btn btn-outline-danger">Document</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default InterviewQuestionsResultsPage;
