import React from "react";

function TheMoviePage() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-4">
          <p>
            Here we are, at one of my favourite projects this semester. The
            project was to create a movie that conveys my and my friend's story
            topic to the audience. We are a duo because our separate topics
            completed each other very well. I am Aleksandar and my friend is
            Aleksandra. Our topic is - The power of positive thinking. This is
            really interesting matter for both of us, which bother our mind from
            quite some time. That is why we decided to try and convey the
            message to the others with a short movie.
          </p>
        </div>
        <div className="col-4">
          <p>
            Let us now dive into the process and see how it went. First and
            foremost, i loved the collaboration with Aleksandra. Everything was
            so punctual and on the point. We were like well assembled machine.
            Now, let us go back to the story. We started with an idea of
            creating a book-like mediation. We started by creating the
            protagonist and his ally, then the story script itself and finally
            we come to the moment of mediating it. Since, i have been interested
            in video editing for the last few months, i proposed to Aleksandra
            that we make a movie out of it. She was open for this adventure, as
            always, so we hit the road.
          </p>
        </div>
        <div className="col-4">
          <p>
            Before jumping into the movie making, we had to structure and edit
            our script a few times, so that we achive the mission of ours and
            convey the message. After we were done, the fun part was about to
            begin, the shooting. We devided it into two acts. We shot the a few
            scenes for the first act, and I sat down to edit them. It took lots
            of time, nerves and effort. But i did it and it was about time to
            shoot the second act. Here we had a bit of a hurdle, the Covid-19.
            We had to improvise and work from distance. That is what we did. We
            divided the work. She was in charge for the voice overs and i was
            responsible for shooting the last scenes and putting everying
            together. I would say the final product is amazing. Enjoy the movie!
          </p>
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div class="alert alert-danger" role="alert">
          Turn on the sound because there is a story to listen to!
        </div>
      </div>
      <div className="row justify-content-md-center">
        <iframe
          src="https://player.vimeo.com/video/499618213?controls=0&autoplay=1&loop=1&muted=1&autopause=0"
          width="1240"
          height="500"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
}

export default TheMoviePage;
