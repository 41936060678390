import React from 'react'

import './DetailsPage.css'

function EmotionsResearchPage(){
    return( 
        <div className="container-fluid">
            <iframe width="100%" height="100%" src="https://sway.office.com/s/g9NDAnW21ESwiIJK/embed" frameborder="0" marginheight="0" marginwidth="0" max-width="100%" max-height="100%" sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts"  style={{"border": "none", "max-width": "100%", "max-height": "100vh"}} allowfullscreen mozallowfullscreen msallowfullscreen webkitallowfullscreen></iframe>
        </div>
    );
}

export default EmotionsResearchPage;