import React from 'react'

import './CardComponent.css'
import { Router, useHistory } from 'react-router';


function PulseCardComponent(props){
    const history = useHistory();

    return (
        <div className="card" id="pulse">
            <img className="card-image" src={props.image}/>
            <div className="content">
                <h3>{props.title}</h3>
                <p>{props.content}</p>
            </div>
        </div>
    );
}

export default PulseCardComponent;