import React from 'react'

import CardComponent from '../../components/card/CardComponent'
import PulseCardComponent from '../../components/card/PulseCardComponent'

import autozen from '../../assets/autozen.svg'
import manifesto from '../../assets/manifesto.png'

import './CriticalDesign.css';

function CriticalDesign() {
    return (
        <div className="container-fluid">
            <div className="row ">
                <div className="col-4">
                    <CardComponent link="manifesto" image={manifesto} title="Personal Manifesto" content="
                    The manifesto was kind of unclear to me how to do it in the beginning. I had to study thoroughly how is personal manifesto done. Then, I had to choose what kind of media to use to display it. My choice for media was influenced by the latest trend in my live, video editing. I chose to make short movie/video in which I will show some of the meaningful and valuable things in my life.
                    "/>
                </div>
                <div className="col-4">
                    <CardComponent link="project-autozen" image={autozen} title="Project Autozen" content="
                    For this assingment we had to think of a topic of interest to us and create a concept/vision for it. Since i am deeply interest in car manifacturing and car industry as a whole, i chose my topic to be connected to automobiles."/>
                </div>
                <div className="col-4">
                    <PulseCardComponent  title="Personal Reflection" content="
                    I find this course very helpful in terms of thinking critically about the design of each application. I always hated spending too much time on researching, brainstorming, and prototyping iteratively and just wanted to go directly to developing the application. However, as I understood from the course, these are vital steps for making a product that has a business value. With the manifesto, I had the chance to improve my knowledge in Adobe Premier and editing/producing video content. With the personal project I had the chance to learn a new skill in terms of prototyping with Adobe XD. It was not only experience in term of new tools but also in terms of designing more consistent layout with modern vision."/>
                </div>
            </div>
        </div>
    );
}

export default CriticalDesign;