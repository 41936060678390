import React from "react";

import "./CardComponent.css";
import { Router, useHistory } from "react-router";

function CardComponent(props) {
  const history = useHistory();

  return props.link !== undefined ? (
    <div onClick={() => history.push(`${props.link}`)} className="card">
      <img className="card-image" src={props.image} />
      <div className="content">
        <h3>{props.title}</h3>
        <p>{props.content}</p>
      </div>
    </div>
  ) : (
    <div className="card">
      <img className="card-image" src={props.image} />
      <div className="content">
        <h3>{props.title}</h3>
        <p>{props.content}</p>
      </div>
    </div>
  );
}

export default CardComponent;
