import React from "react";
import CardComponent from "../../components/card/CardComponent";
import PulseCardComponent from "../../components/card/PulseCardComponent";
import vegaImage from "../../assets/vega.jpg";
import additive from "../../assets/additive.png";
import interviewResults from "../../assets/interview-results.png";
import prototype from "../../assets/prototype.png";
import stakeholder from "../../assets/stakeholder.png";
import tests from "../../assets/tests.png";
import components from "../../assets/components.png";

function Projects() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-4">
          <CardComponent
            link="details-vega"
            image={vegaImage}
            title="Vega Collaboration"
            content="This minor kicked-off with an intercultural project with a school from South Africa. 
                            This was the first time Fontys makes such a long-distance collaboration with another school.
                            The project was about creating our own story world which will convey the message of the movie 
                            Before the flood using transmedia storytelling."
          ></CardComponent>
        </div>
        <div className="col-4">
          <CardComponent
            link="details-interview-questions"
            image={additive}
            title="User Research Interview Questions"
            content="I have created the interview questions used by some of my group memebers to conduct a user interviews.
                            I also used the questions to interview one of our target users. I used open specific questions followed by deep questions.
                            Using this strategy, we managed to make the interviewee feel comfortable and also get the right answers which also verified our assumptions."
          ></CardComponent>
        </div>
        <div className="col-4">
          <CardComponent
            link="details-interview-questions-results"
            image={interviewResults}
            title="K3D Interview Results/Report"
            content="K3D is one of the customers of Additive Industries which a group of two students, including me, interviewed. The interview was online using Microsoft Teams and the feature screenshare. This results document was created to show the outcome of the interview and make a brief conclusion."
          ></CardComponent>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <CardComponent
            link="stakeholders"
            image={stakeholder}
            title="Stakeholder Analysis"
            content="Our communication with the client, Additive Industries, did not start on the right foot. We were waiting a lot for response, thus losing precious time in the project progress.
                            I decided that it would be nice to create a super basic persona with the little info we received after the briefing at school."
          ></CardComponent>
        </div>
        <div className="col-4">
          <CardComponent
            link="prototypes"
            image={prototype}
            title="Prototypes"
            content="Each one of us had to make a prototype for at least two components of the application. I choose to work on the notifications, the machine job history and the machine details screens. For the notifications i started with sketches but for the other two topics i just experimented in Adobe XD. I was mainly inspired by Mike and Bart's designs."
          ></CardComponent>
        </div>
        <div className="col-4">
          <CardComponent
            link="usability-test"
            image={tests}
            title="Usability Tests"
            content="Here I took a big part by creating a usability test plan and conducting several usability tests with some students. I would say that this was one of the best experiences so far, because I learned a lot about different test methods, especially the one I used - first click, guerilla testing and assessment research."
          ></CardComponent>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <CardComponent
            link="design-systems"
            image={components}
            title="Design Systems"
            content="This was my last task for the project with Additive Industries. I had to code some of the components like they we in the design sheets. This was made, so we can transfer the project to the company and the others to take it up to have a easy way to integrate what we designed."
          ></CardComponent>
        </div>
        <div className="col-4">
          <PulseCardComponent
            title="Personal feedback - Additive Industries project"
            content="In the end of my minor and my 15weeks project with Additive industries, i would like to look back and reflect on what went right and what wrong.
            The project started slowly, so was the communication in the team, but after a few weeks we built up some speed and the bike started rolling. I felt nice in that team of Mike, Max A., Max K. Aleksandra and Bart. It was always a cosy atmosphere on the meetings and we managed to plan the tasks well.
            I would say that from soft-skills perspective i developed and opened a bit more, still lots of work to put until i feel comforatable.
            I tried and definitely did participate a lot in the project, maybe not as much as some of the members, but i would like to think that i did my job well. Of course, there is always room for improvement. I am happy with the final product and glad to see that the cliets of Additive industries are feeling that way, as well. It was nice collaboration, although most of the time remotely. It is what it is, I am happy :) and i will go through it again with this people without hesitation!"
          ></PulseCardComponent>
        </div>
      </div>
    </div>
  );
}

export default Projects;
