import React from "react";

import PDF from "../../documents/Stakeholders Analysis.pdf";
import stakeholder from "../../assets/stakeholder.png";

import "./DetailsPage.css";

function StakeholderProfilesPage() {
  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row justify-content-md-center">
        <h1>Stakeholder Analysis</h1>
      </div>
      <div className="row">
        <div className="col-6">
          <p>
            {" "}
            Our communication with the client, Additive Industries, did not
            start on the right foot. We were waiting a lot for response, thus
            losing precious time in the project progress. I decided that it
            would be nice to create a super basic persona with the little info
            we received after the briefing at school. Then, this persona was
            used on the first demo and we received feedback on which i started
            updating it with the help of Max A. We went through several
            iterations, starting from a broad description of all possible
            stakeholders and narrowing it down to two specific ones. The
            stakeholder profiles consisted of stories, build to make them come
            alive, user flows, to show thier engagement with the product and
            POV's, to show their point of view on the product.
          </p>
        </div>
        <div className="col-6" align="center">
          <img className="right-image" src={stakeholder}/>
          <figcaption>First sketchty stakeholder iteration</figcaption>
        </div>
      </div>
      <div className="row">
        <div className="button-group">
          <a href={PDF + "#page=2"} target="_blank">
            <button className="btn btn-outline-danger">
              Iteration 1 - Identify & prioritise
            </button>
          </a>
          <a href={PDF + "#page=4"} target="_blank">
            <button className="btn btn-outline-danger">
              Iteration 2 - POV's and user stories
            </button>
          </a>
          <a href={PDF + "#page=7"} target="_blank">
            <button className="btn btn-outline-danger">
              Iteration 3 - Build the user flows
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default StakeholderProfilesPage;
