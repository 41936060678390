import React from 'react'

import './DetailsPage.css'

function DesignProbesPage(){
    return( 
        <div class="padlet-embed" style={{"border":"1px solid rgba(0,0,0,0.1)", "border-radius":"2px", "box-sizing":"border-box", "overflow":"hidden", "position":"relative", "width":"100%", "height":"780px", "background":"#F4F4F4"}}><p style={{"padding":0, "margin":0}}><iframe src="https://padlet.com/embed/qlh0lii5ygt90ztn" frameborder="0" allow="camera;microphone;geolocation" style={{"width":"100%", "height":"780px", "display":"block", "padding":0, "margin":0}}></iframe></p><div style={{"padding":"8px", "text-align":"right", "margin":0}}><a href="https://padlet.com?ref=embed" style={{"padding":0, "margin":0, "border":"none", "display":"block", "line-height":1, "height":"16px"}} target="_blank"><img src="https://padlet.net/embeds/made_with_padlet.png" width="86" height="16" style={{"padding":0, "margin":0, "background":"none", "border":"none", "display":"inline", "box-shadow":"none"}} alt="Made with Padlet"></img></a></div></div>
    );
}

export default DesignProbesPage;