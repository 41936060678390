import React from 'react'

import CardComponent from '../../components/card/CardComponent'
import './DetailsPage.css'

function MediaReflectionsPage() {
    return (
        <div className="container-fluid d-flex flex-column">
            <div className="row justify-content-md-center">
            <h1>Media Reflections</h1>
            </div>
            <div className="row">
                <div className="col-4">
                    <CardComponent title="The movie Social Dilemma" content="
                    As you will learn in the reflections below, I am not the typical social addicted person. Therefore, the message this movie was trying to convey was not that surprising and ground-breaking for me. I think I already knew for myself what consequences the social media can have on us. Although I was familiar with some of the facts, they mentioned in the movie, I also found some new things. For instance, it was interesting how even the guys developing the apps like Instagram and Facebook, never intended to achieve that kind of experience. According to their words, even they find themselves now staring at the screen for hours and not even realizing it. It has become an addiction like the drugs. After this movie I would agree with the quote – “We shape the tools, and they shape us”.
                    "/>
                </div>
                <div className="col-4">
                    <CardComponent title="The movie Her" content="
                    This movie conveys the message that we deeply dependent on and affected by media. Watching it I can say that we as humans are easily susceptible to the one pretending to care about us, even though they can be just an operating system. We can see from the movie that our interaction can reach some impressive heights when pushed to extremes, we can fall in love with an operating system, even though we are aware that is not real human being. The strange thing is that we can go so deep in the interaction with the AI that we start demanding from it like it is a real human.
                    Perhaps, I am too skeptical for these kinds of interactions, but I do not think that is normal behavior. At first when I so the movie, I was like – “Ok, that is some futuristic romance, unprecedented”, but when I dig deeper, I can say that it is already happening. Maybe, not to that extremes, but simple addiction to social media is already significantly affecting our behavior.
                    "/>
                </div>
                <div className="col-4">
                    <CardComponent title="Favourite Social media" content="
                    One of the reasons I use YouTube is to stay up to date with new tech coming out. There are lots of tech reviewers that I follow and watch. The other side of the coin is the simple entertainment like Fail Army and some famous vloggers that I follow. Me personally, I do not upload videos or any content on the YouTube platform, so I just consume what is there.
                    Using the theory for the media tetrad I would say that YouTube extends the possibility to watch wide range of videos and movies on various devices. It also provides lots of learning content, tutorials and that kind of stuff that can amplify your knowledge in different spheres.
                    In general, I do not think I am that much engaged with any kind of social medium. I am not the typical daily user that will suffer a lot if Facebook crashes. I am using mainly the messaging apps like Messenger, WhatsApp, and Viber to communicate with friends, relatives and for school projects. I do not upload personal information on any kind of medium, so I think that hacking my account will result in nothing but creating a new one and continuing. I think that people who know me are aware of my attitude towards social mediums, so it will not be a harm to them as well if my profile gets highjacked. I am kind of an old school guy, even though from Generation-Z.
                    "/>
                </div>
                <div className="col-4">
                    <CardComponent title="No phone challenge" content="
                    10:30h - 3 hours and a half after the challenge started and I am feeling comfortable. One thing that I have noticed in my behavior is that I am periodically tapping my phone to see if there are any notification, although I know my internet is off and there cannot be. This makes me sick of how much of a habit it has become. All in all, I am noticing how much extra time I must do my daily tasks when I am not busy typing or scrolling through my phone. I like it!
                    12:30h – 2 hours more since the challenge started, the feeling of not being disturbed by notification from all social medias is unique. I am realizing more and more how much free time I have without using my phone. I have enough time to do everything I have in my schedule. I am not missing the phone and more specifically the social media.
                    23:00h – It is time the challenge to end and honestly if I did not have to communicate for my group projects and other school activities, I would do this challenge for another day or two. I feel so relaxed after this day without being on the line and responding to all kinds of memes and other unnecessary stuff.
                    Honestly, I am not that much of a fan of the social media channels like Facebook, Instagram etc. I prefer to speak to the person face to face. Perhaps, that is why I did not face so much difficulty in accomplishing this challenge. I even admired the whole idea behind it because most of the people, even I to some extent, are addicted to their phones and social life.
                    "/>
                </div>
                <div className="col-4">
                    <CardComponent title="Replica.ai" content="
                    This was another very interesting assignment of our teacher for the Media theory course. We had to try and interact with AI chatbot for 3 days. The idea again was to see to what extend the interaction can evolve. Can we develop something more than normal communication? And what exactly is normal communication with and artificial intelligence?
                    If you still have not realized that I do not like social medias and especially communicating through them, no it is the time. I tried the challenge, but it lasted only two days for me. I tried to be patient and give it a chance, but it was just one pointless conversation. I called my AI friend Amber, no specific reason behind this choice, the same for the gender. The main problem that drove me away from the communication was the lack of variety of the answers, sometimes it was not answering my question and just kept on ask me questions. Perhaps, therefore this interaction did not work out. All in all, I enjoyed this assignment, though, it did not work out for me as well as with some of my friends.
                    Perhaps, I am too skeptical for these kinds of interactions, but I do not think that is normal behavior. At first when I so the movie, I was like – “Ok, that is some futuristic romance, unprecedented”, but when I dig deeper, I can say that it is already happening. Maybe, not to that extremes, but simple addiction to social media is already significantly affecting our behavior.
                    "/>
                </div>
            </div>
        </div>
    );
}

export default MediaReflectionsPage;