import React from "react";

import CardComponent from "../../components/card/CardComponent";
import PulseCardComponent from "../../components/card/PulseCardComponent";

import emotions from "../../assets/emotions.png";
import lawofattraction from "../../assets/lawofattraction.png";
import movie from "../../assets/themovie.png";

import "./StoryCreation.css";

function StoryCreation() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-4">
          <CardComponent
            link="emotions-research"
            image={emotions}
            title="Storytelling & Emotions"
            content="Storytelling is a way to communicate. People have used stories to entertain themselves and
                        learn new things for a long time. It is easier to remember some facts when they are told with
                        a nicely narrated story. Furthermore, we have our human emotions which play a significant
                        role in storytelling. Human emotions are really complicated and quite relative subject to
                        understand."
          />
        </div>
        <div className="col-4">
          <CardComponent
            link="pecha-kucha"
            image={lawofattraction}
            title="Pecha-kucha"
            content="Furthermore, we had to think of a topic for a story that we will create in a later stage of the course and present it using Pecha Kucha presentation method 20 X 20. For the topic I had to think a lot and research, but then I stopped on what is interesting to me in the last few years and just stick to it. I made the Pecha Kucha using the guidelines from the official site. The topic is – Law of Attraction."
          />
        </div>
        <div className="col-4">
          <CardComponent
            link="the-movie"
            image={movie}
            title="The movie"
            content="Here we are, at one of my favourite projects this semester. The
            project was to create a movie that conveys my and my friend's story
            topic to the audience. We are a duo because our separate topics
            completed each other very well. I am Aleksandar and my friend is
            Aleksandra. Our topic is - The power of positive thinking. This is
            really interesting matter for both of us, which bother our mind from
            quite some time. That is why we decided to try and convey the
            message to the others with a short movie."
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <PulseCardComponent
            title="Personal Reflection"
            content="Before starting the course, I had no idea what storytelling is, except
            from the exact meaning of the word. I had never done it and I did not
            know what I am supposed to do, especially for the Vega project. I was
            totally not in my field of expertise. However, after I few lectures,
            research and consultation with our friends and teammates from South
            Africa I started feeling it. For the Vega project we had to come up
            with a main story accompanied with a few side stories around so that
            it creates a story world. I came up with our main story which turned
            out to be the best one after we voted in the team. I was proud of
            myself for the progress I made for only two weeks. I gained more confidence and I started really enjoying the
            course, it gave freedom to my imagination. Then, the next remarkable
            part of the course was the one with the story creation itself. I
            learned how to structure a story plot and how to convey my message
            using the story. I also learned a lot about video editing because I used Adobe Premiere for making our story into a short Movie, more on which you can find in a separate card."
          />
        </div>
      </div>
    </div>
  );
}

export default StoryCreation;
