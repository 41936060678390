import React from "react";

import "./LandingPage.css";

function LandingPage() {
  return (
    <div className="container-fluid">
      <div className="landing-page-bgimage">
        <div className="heading">
          <h2>Hii there, I am Aleksandar! Welcome to my Digital Experience Design Portfolio!</h2>
          <p>
            This portfolio is divided into separate sections, one for each
            subject i had during the minor. Next to that is the section with the
            projects, namely the Vega collaboration with the South African
            school and the bigger one with our client Additive industries.
          </p>
          <p>&nbsp;</p>
          <p>
            The information in each sections is depicted in cards. To receive
            more information on the specific matter, just click on the card and
            a page including, but not limited to, imagery and related documents,
            will pop-up.
          </p>
          <p>&nbsp;</p>
          <p>
            There will be a reflection card at the bottom of the each main
            subject section or in the specific assignment card section, where
            applicable of course.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
