import React from 'react'

import PDF1 from '../../documents/LawOfAttraction.pdf'

import './DetailsPage.css'

function PechaKuchaPage(){
    return( 
        <div className="container">
            <iframe src={PDF1} frameborder="0" width="100%" height="100%"></iframe>
        </div>
    );
}

export default PechaKuchaPage;