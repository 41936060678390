import React from "react";
import CardComponent from "../../components/card/CardComponent";
import PulseCardComponent from "../../components/card/PulseCardComponent";

import probes from "../../assets/probes.jpg";
import flaws from "../../assets/flaws.png";
import additive from "../../assets/additive.png";
import interviewResults from "../../assets/interview-results.png";
import prototype from "../../assets/prototype.png";
import stakeholder from "../../assets/stakeholder.png";
import tests from "../../assets/tests.png";

import "./HumanCenteredDesign.css";

function HumanCenteredDesign() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-4">
          <CardComponent
            link="design-probes"
            image={probes}
            title="Design Probes"
            content="
                    Me and Bart created a simple design probe called - The trash bin challenge, to see whether people are aware how to recycle in their daily lives. 
                    "
          />
        </div>
        <div className="col-4">
          <CardComponent
            link="design-flaws"
            image={flaws}
            title="Interaction Design Flaws"
            content="
                    To show our understanding of interaction design, we were asked to find 3 interaction design flaws in the environment around us. We had to explain how they confuse the user and suggest an appropriate solution."
          />
        </div>
        <div className="col-4">
          <CardComponent
            link="details-interview-questions"
            image={additive}
            title="User Research Interview Questions"
            content="I have created the interview questions used by some of my group memebers to conduct a user interviews.
                            I also used the questions to interview one of our target users. I used open specific questions followed by deep questions.
                            Using this strategy, we managed to make the interviewee feel comfortable and also get the right answers which also verified our assumptions."
          ></CardComponent>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <CardComponent
            link="details-interview-questions-results"
            image={interviewResults}
            title="K3D Interview Results/Report"
            content="K3D is one of the customers of Additive Industries which a group of two students, including me, interviewed. The interview was online using Microsoft Teams and the feature screenshare. This results document was created to show the outcome of the interview and make a brief conclusion."
          ></CardComponent>
        </div>
        <div className="col-4">
          <CardComponent
            link="stakeholders"
            image={stakeholder}
            title="Stakeholder Analysis"
            content="Our communication with the client, Additive Industries, did not start on the right foot. We were waiting a lot for response, thus losing precious time in the project progress.
                            I decided that it would be nice to create a super basic persona with the little info we received after the briefing at school."
          ></CardComponent>
        </div>
        <div className="col-4">
          <CardComponent
            link="prototypes"
            image={prototype}
            title="Prototypes"
            content="Each one of us had to make a prototype for at least two components of the application. I choose to work on the notifications, the machine job history and the machine details screens. For the notifications i started with sketches but for the other two topics i just experimented in Adobe XD. I was mainly inspired by Mike and Bart's designs."
          ></CardComponent>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <CardComponent
            link="usability-test"
            image={tests}
            title="Usability Tests"
            content="Here I took a big part by creating a usability test plan and conducting several usability tests with some students. I would say that this was one of the best experiences so far, because I learned a lot about different test methods, especially the one I used - first click, guerilla testing and assessment research."
          ></CardComponent>
        </div>
        <div className="col-8">
          <PulseCardComponent
            title="Personal Reflection"
            content="Personally, I prefer to focus more on the problem-solving part of the development process. This can be easily explained from the fact that I am a software student, and I do not really pay attention to the research part. I know it is not right, but i do. I do prefer to focus on coding, than talking to users, conducting interviews, tests, etc. By choosing this minor I aimed at changing that because I understand the importance of this part. My improvement started by taking huge part in the creation of the interview questions for our design challenge client, Additive Industries. I also took part in one of the interviews using this question to interview one of our company’s clients, K3D. Doing the interview was definitely uncomfortable at the beginning but not something impossible. Me and my colleague managed to collect useful information. Then, I took part in the brainstorming session, which was one of the few in my professional experience so far, so I would consider it as a learning outcome from the course, as well. As a result of the research and brainstorming assignments, I really saw the benefit of going through these vital processes before jumping into implementation. I have tried to put into practice what i learned and it can be seen in the process of making my critical design project, but also my portfolio.
            Both used to be quite ugly and rusty but after a few iterations and feedback processing i managed to put out a nice looking products. I definitely have to work on taking more the initiative and not staying that much in the back, but this is my next step i guess."
          ></PulseCardComponent>
        </div>
      </div>
    </div>
  );
}

export default HumanCenteredDesign;
