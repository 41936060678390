import React from "react";

import PDF1 from "../../documents/ideaselcars.pdf";
import PDF2 from "../../documents/ideasknowledge.pdf";
import PDF3 from "../../documents/conceptknowledge.pdf";

import "./DetailsPage.css";

function AutozenConceptPage() {
  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row justify-content-md-center">
        <h2>Autozen</h2>
      </div>
      <div className="row">
        <div className="col-6">
          <p>
            This assignment started slowly for me because i was not able to
            think of a topic right away. I was desperately needing a topic, so i
            just throw - Electric cars. This was way too broad and i was not
            sure what exactly i want to achieve with it. I did some
            brainstorming with my design challenge team and we thought of some
            ideas, which can be seen in the document below. This was not a
            successful attemp because most of the ideas were already present.
            This was the time i realised i need a new topic. I came up with the
            one - Lack of knowledge about cars. Again i brainstormed some ideas
            from this topic with some of my team mates and also using the Dunne
            & Raby definition of critical design and Plex cards. I have put all
            my thoughts around the concept in the other document linked below.
          </p>
        </div>
        <div className="col-6">
          <p>
            After my idea and concept got accepted by the teacher i went for
            creating a more sophisticated digital prototype using Adobe XD, my
            first encounter with the tool. I went for 3 different iterations
            with the prototyping. After each of them i received a feedback on
            which i worked. My first prototype was a bit messy and not organized
            in terms of layout elements. I used too many different colors and
            fonts. It was not looking following any design principle at all.
            Thus, in the next iteration i worked more on making it looking more
            organized, following one pattern and more importantly consistent in
            terms of layout. I made new buttons and made the cards look like
            they pop-up. The next step was to work on my logo and again fix some
            remaining inconsistencies. I definitely left my creative touch on
            the protoype in the end. Bellow you can find 3 links to the 3
            different videos of my prototype. They are videos because i was not
            allowed by Adobe do create links to my actual working prototypes. I
            can say that my abilities to design went from 0 to 50% and it is
            visible when you compare the separate prototypes.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-3" align="center">
          <p style={{ textAlign: "center" }}>First Prototype</p>
          <div style={{ padding: "223.93% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/484210179?controls=0&autoplay=1&loop=1&muted=1&autopause=0"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <div className="col-6" align="center">
          <p style={{ textAlign: "center" }}>Second Prototype</p>
          <div style={{ padding: "60.4% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/484206979?controls=0&autoplay=1&loop=1&muted=1&autopause=0"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <div className="col-3" align="center">
          <p style={{ textAlign: "center" }}>Final Prototype</p>
          <div style={{ padding: "223.93% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/496366411?controls=0&autoplay=1&loop=1&muted=1&autopause=0"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
      <div className="row">
        <div className="button-group">
          <a href={PDF1} target="_blank">
            <button className="btn btn-outline-danger">
              Ideas electric cars
            </button>
          </a>
          <a href={PDF2} target="_blank">
            <button className="btn btn-outline-danger">
              Ideas lack of knowledge topic
            </button>
          </a>
          <a href={PDF3} target="_blank">
            <button className="btn btn-outline-danger">
              Concept lack of knowledge topic
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AutozenConceptPage;
