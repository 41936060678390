import React from "react";

import PDF1 from "../../documents/Interview plan user interview.pdf";
import PDF2 from "../../documents/Assumptions & insights per stakeholder.pdf";
import questions from "../../assets/interview-questions.png";

import "./DetailsPage.css";

function InterviewQuestionsPage() {
  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row justify-content-md-center">
        <h1>User Research Interview Questions</h1>
      </div>
      <div className="row">
        <div className="col-6">
          <p>
            I have created the interview questions used by some of my group
            memebers to conduct user interviews. I also used the questions to
            interview one of our target users. I used open specific questions
            followed by deep questions. Using this strategy, we managed to make
            the interviewee feel comfortable and also get the right answers
            which also verified our assumptions.
            <p>&nbsp;</p>
            The questions can be found in the picture on the left or in the
            document linked below. They are in the last, Task section. I have
            also linked below the assumptions and insights we gathered and/or
            verified with the execution of the separate interviews.
          </p>
        </div>
        <div className="col-6" align="center">
          <img className="right-image" src={questions}/>
        </div>
        <p>&nbsp;</p>
      </div>
      <div className="row">
        <div className="button-group">
          <a href={PDF1} target="_blank">
            <button className="btn btn-outline-danger">
              Test plan with interview questions
            </button>
          </a>
          <a href={PDF2} target="_blank">
            <button className="btn btn-outline-danger">Assumptions & Insights</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default InterviewQuestionsPage;
