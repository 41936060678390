import React from "react";

import components from "../../assets/components.png";

function DesignSystems() {
  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row justify-content-center">
          <h1>Design Systems</h1>
      </div>
      <div className="row">
        <div className="col-6">
          <p>
            This was my last task for the project with Additive Industries. I
            had to code some of the components like they we in the design
            sheets. This was made, so we can transfer the project to the company
            and the others to take it up to have a easy way to integrate what we
            designed. My tasks were to design the card design and the sidebar
            design. The results can be found in the link below. We hosted the
            application using netlify. The application itself is written in
            ReactJS and displayed in storybooks. Storybooks was another new
            thing to me that i had to learn, but with some help from Max A., i
            got it going. I really enjoyed that part, because it is closer to
            what i do, desipe it is still a front-end work. The section
            components in the storybook site is where my work is.
          </p>
        </div>
        <div className="col-6" align="center">
          <img className="right-image" src={components} />
        </div>
      </div>
      <div className="row">
        <div className="button-group">
          <a
            href="https://baammm-additive-industries.netlify.app/?path=/story/introduction--page"
            target="_blank"
          >
            <button className="btn btn-outline-danger">
              Storybook designs
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default DesignSystems;
