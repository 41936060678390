import React from "react";

import PDF1 from "../../documents/essay.pdf";
import PDF2 from "../../documents/essay-v2.pdf";
import detailsImage from "../../assets/essay.png";

import "./DetailsPage.css";

function EssayDetailsPage() {
  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row justify-content-md-center">
        <h1>The Essay</h1>
      </div>
      <div className="row">
        <div className="col-6">
          <p>
            This essay was one of the hardest parts of this minor for me. I was
            definitely not expecting to have to write essay again, but this was
            my faith. I was struggling a lot in finding my perfect topic.
          </p>
          <p>
            My essay topic is – “Are designers designing a product or an
            opportunity for experience? “{" "}
          </p>
          <p>
            As a future designer, I would like to learn a bit more about the
            evolution of design and what exactly is the right thing to focus on
            when designing. I had a vision formulated in my mind, that we first
            design for the user, than for the service, that is why I wanted to
            argument it in this essay. In my opinion there still lots of
            companies struggling to find that right strategy towards successful
            and loved product from the potential users.
          </p>
          <p>
            I have attached below two version of my essay iterations. The first
            one is the earliest one and then the final one.
          </p>
        </div>
        <div className="col-6" align="center">
          <img className="right-image" src={detailsImage} />
        </div>
      </div>
      <div className="row">
        <div className="button-group">
          <a href={PDF1} target="_blank">
            <button className="btn btn-outline-danger">Essay v1</button>
          </a>
          <a href={PDF2} target="_blank">
            <button className="btn btn-outline-danger">Essay v2</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default EssayDetailsPage;
