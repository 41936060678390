import React from "react";

import CardComponent from "../../components/card/CardComponent";

import emotions from "../../assets/emotions.png";
import lawofattraction from "../../assets/lawofattraction.png";

import "./DetailsPage.css";

function ManifestoPage() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-5">
          <h2>First Iteration</h2>
          <p>
            The manifesto was kind of unclear to me how to do it in the
            beginning. I had to study thoroughly how is personal manifesto done.
            Then, I had to choose what kind of media to use to display it. My
            choice for media was influenced by the latest trend in my live,
            video editing. I chose to make short movie/video in which I will
            show some of the meaningful and valuable things in my life. For this
            first version of the manifesto i just throw some random photos which
            are self-explanatory. I wanted to let the consumer to interpret for
            themselves. However, this was not the goal of this assignment, which
            i understood after talk with Woody. I knew i have to change
            something and make it more personal, showing my thoughts.
          </p>
        </div>
        <div className="col-7" align="center">
          <div style={{ padding: "60.4% 0 0 0", position: "relative" }}>
            <iframe
              src="https://www.youtube.com/embed/8tugYdos1ys"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <h2>Second Iteration</h2>
          <p>
            In this second iteration of the manifesto i added more personal
            images accompanied with lots of personal thoughts. I paid more
            attention on the style side of the video, meaning i used one font
            and more consistent transitions. I would like to believe i managed
            to produce a better product in the end.
          </p>
        </div>
        <div className="col-7" align="center">
          <div style={{ padding: "60.4% 0 0 0", position: "relative" }}>
            <iframe
              src="https://www.youtube.com/embed/lAGCk5rhyqs"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
    </div>
  );
}

export default ManifestoPage;
