import './App.css';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import {Navbar} from './components/navigation/navbar'
import Projects from './pages/projectPage/Projects';
import VegaDetailsPage from './pages/detailsPages/VegaDetailsPage';
import LandingPage from './pages/landingPage/LandingPage';
import InterviewQuestionsPage from './pages/detailsPages/InterviewQuestionsPage';
import InterviewQuestionsResultsPage from './pages/detailsPages/InterviewQuestionsResultsPage';
import StakeholderProfilesPage from './pages/detailsPages/StakeholderProfilesPage';
import PrototypesPage from './pages/detailsPages/PrototypesPage';
import UsabilityTestPage from './pages/detailsPages/UsabilityTestPage';
import MediaTheory from './pages/mediaTheoryPage/MediaTheory';
import EssayDetailsPage from './pages/detailsPages/EssayDetailsPage';
import MediaReflectionsPage from './pages/detailsPages/MediaReflectionsPage';
import StoryCreation from './pages/storycreationPage/StoryCreation';
import EmotionsResearchPage from './pages/detailsPages/EmotionsResearchPage';
import PechaKuchaPage from './pages/detailsPages/PechaKuchaPage';
import HumanCenteredDesign from './pages/humanCenteredDesignPage/HumanCenteredDesign';
import DesignProbesPage from './pages/detailsPages/DesignProbesPage';
import DesignFlawsPage from './pages/detailsPages/DesignFlawsPage';
import CriticalDesign from './pages/criticalDesignPage/CriticalDesign';
import ManifestoPage from './pages/detailsPages/ManifestoPage';
import AutozenConceptPage from './pages/detailsPages/AutozenConceptPage';
import TheMoviePage from './pages/detailsPages/TheMoviePage';
import DesignSystems from './pages/detailsPages/DesignSystems';


function App() {
  return (
    <div className="body">

        <BrowserRouter>
          <Navbar />
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/projects" component={Projects} />
            <Route path="/media-theory" component={MediaTheory} />
            <Route path="/storytelling" component={StoryCreation} />
            <Route path="/human-centered-design" component={HumanCenteredDesign} />
            <Route path="/critical-design" component={CriticalDesign} />
            <Route path="/prototypes" component={PrototypesPage} />
            <Route path="/stakeholders" component={StakeholderProfilesPage} />
            <Route path="/details-vega" component={VegaDetailsPage} />
            <Route path="/usability-test" component={UsabilityTestPage} />
            <Route path="/emotions-research" component={EmotionsResearchPage} />
            <Route path="/pecha-kucha" component={PechaKuchaPage} />
            <Route path="/details-interview-questions" component={InterviewQuestionsPage} />
            <Route path="/details-interview-questions-results" component={InterviewQuestionsResultsPage} />
            <Route path="/details-essay" component={EssayDetailsPage} />
            <Route path="/details-assignments" component={MediaReflectionsPage} />
            <Route path="/design-probes" component={DesignProbesPage} />
            <Route path="/design-flaws" component={DesignFlawsPage} />
            <Route path="/manifesto" component={ManifestoPage} />
            <Route path="/project-autozen" component={AutozenConceptPage} />
            <Route path="/the-movie" component={TheMoviePage} />
            <Route path="/design-systems" component={DesignSystems}/>
          </Switch>
        </BrowserRouter>
    </div>
  );
}

export default App;
