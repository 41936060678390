import React from "react";

import PDF1 from "../../documents/Usability Test Plan.pdf";
import PDF2 from "../../documents/Usability Test Report.pdf";
import tests from "../../assets/tests.png";

import "./DetailsPage.css";

function UsabilityTestPage() {
  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row justify-content-md-center">
        <h1>Usability Tests</h1>
      </div>
      <div className="row">
        <div className="col-6">
          <p>
            Here I took a big part by creating a usability test plan and
            conducting several usability tests with some students. I would say
            that this was one of the best experiences so far, because I learned
            a lot about different test methods, especially the one I used -
            first click, guerilla testing and assessment research. I chose these
            methodologies because they best fit our test purpose, namely, to
            test people unfamiliar with the product, both remotely and in
            person. The results from the interviews me and Max K conducted gave
            us insights on which parts of the interface are and are not usable
          </p>
          <p>
            In the link below you can find, first, the test plan, second, the
            test report combining all test results with a brief conclusion and rated recommendations.
          </p>
        </div>
        <div className="col-6" align="center">
          <img
            className="right-image"
            src={tests}
          />
        </div>
      </div>
      <div className="row">
        <div className="button-group">
          <a href={PDF1} target="_blank">
            <button className="btn btn-outline-danger">
              Usability Test Plan
            </button>
          </a>
          <a href={PDF2} target="_blank">
            <button className="btn btn-outline-danger">
              Usability Test Report
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default UsabilityTestPage;
